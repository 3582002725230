.App {
  text-align: center;
  text-decoration-line: none;
}

nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 10vh;
  background: rgb(73, 79, 82);
  color: white;
  /* overflow: hidden; */
  position: fixed;
  width: 100%;
}

/* a {
  target-name: new;
  target-new: tab;
} */

.Main-Page-Logo {
  position: static;
  height: auto;
  max-height: 50vh;
}

.App-pages {
  margin-top: 17vh;
  margin-bottom: 10vh;
}

.nav-Links {
  width: 50%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  list-style: none;
  color: white;
}

.nav-Link {
  float: right;
  text-decoration: none;
}

.nav-Logo {
  height: 8vmin;
  width: auto;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.site-Pages {
  width: 60%;
}




/*Default Original Below*/

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) { */
  /* .App-logo {
    animation: App-logo-spin infinite 20s linear;
  } */
/* }

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  } 
} */
